import { Chart } from "../../chart.model";

const doWork = (
  data: Array<any>,
  baseKey: string,
  lang: string,
  parameters: any,
  descriptors: Array<any>,
  drilledMethod: string,
  payload: any,
  routinesObject: any
): any => {

  // Gestion des routines
  const routines =
    routinesObject &&
      routinesObject.hasOwnProperty("routines") &&
      routinesObject.routines &&
      routinesObject.routines.length
      ? [...routinesObject.routines].sort((a, b) => b.isBench - a.isBench)
      : [];

  const [baseline, ...rest] = data;
  const operator = baseline.operator || null;

  if (operator === "DOUBLE_BASELINE") {

    // Itère sur chaque tableau de score dans 'rest'
    rest.forEach((scoreItem: any, scoreIndex: number) => {
      processDataWithDoubleBaseline(baseline, scoreItem, routines, payload, lang, baseKey);
    });

    // Mise à zéro de baseline après calcul
    resetBaselineToZero(baseline, baseKey);
  } else {
    // Cas où il n'y a pas de DOUBLE_BASELINE
    resetBaselineToZero(baseline, baseKey);

    // Process normal pour calculer les données
    const y = processNormalData(baseline, rest, routines, payload, lang, baseKey);
    return y;
  }
};

// Processus de données pour le cas DOUBLE_BASELINE
const processDataWithDoubleBaseline = (
  baseline: any,
  scoreItem: any,
  routines: any[],
  payload: any,
  lang: string,
  baseKey: string
) => {
  // Réduction des données pour chaque tableau de score
  return [baseline, scoreItem].reduce((accumulator: any, item: any) => {
    if (item.hasOwnProperty("values") && Array.isArray(item.values)) {
      const keyLabel = getKeyLabel(item, lang);
      accumulator.categories[keyLabel] = item.attribute;

      item.values.reduce(
        (reducer: { categories: object; series: object }, object: any, idx: number) => {
          const { keySerie, color } = getKeySerieAndColor(object, idx, routines, lang);
          reducer.series[keySerie] = reducer.series[keySerie] || { name: keySerie, data: [], color };

          // Calcul des différences
          const differences = calculateDifferences(baseline, object, baseKey, idx);
          const medianValue = median(differences);

          reducer.series[keySerie].data.push({
            y: medianValue,
            x: Object.keys(accumulator.categories).indexOf(keyLabel),
            details: object.values,
          });

          return reducer;
        },
        accumulator
      );
    }
    return accumulator;
  }, payload);
};

// Calculer les différences entre les valeurs score et baseline
const calculateDifferences = (baseline: any, object: any, baseKey: string, idx: number) => {
  const currentValues = Chart.castValuesToNumber(object.values, baseKey);
  const baselineValues = baseline.values[idx].values;
  return currentValues.map((val: any, i: number) => val[baseKey] - baselineValues[i].value[baseKey]);
};

// Obtenir le label clé pour les données
const getKeyLabel = (item: any, lang: string) => {
  return [
    Chart.getObjectValueTranslation(item.attribute.blockName, lang),
    Chart.getObjectValueTranslation(item.attribute.label, lang),
  ].join("_");
};

// Obtenir le nom de la key et sa couleur
const getKeySerieAndColor = (object: any, idx: number, routines: any[], lang: string) => {
  const keySerie = Chart.getObjectValueTranslation(object.attribute.label, lang);
  const foundRoutine = routines.find((routine: any) => routine.name === keySerie);
  const color = (foundRoutine && foundRoutine.color) ? foundRoutine.color : Chart.colorScale[idx];
  return { keySerie, color };
};

// Remettre le baseline à zéro après traitement
const resetBaselineToZero = (baseline: any, baseKey: string) => {
  baseline.values = baseline.values.map((item: any) => {
    item.values = item.values.map((object: any) => {
      object.value[baseKey] = 0;
      return object;
    });
    return item;
  });
};

// Traitement standard des données (sans DOUBLE_BASELINE)
const processNormalData = (
  baseline: any,
  rest: Array<any>,
  routines: any[],
  payload: any,
  lang: string,
  baseKey: string
) => {
  return [baseline, ...rest].reduce((accumulator: any, item: any, index: number) => {
    if (item.hasOwnProperty("values") && Array.isArray(item.values)) {
      const keyLabel = getKeyLabel(item, lang);
      accumulator.categories[keyLabel] = item.attribute;

      item.values.reduce(
        (reducer: { categories: object; series: object }, object: any, idx: number) => {
          const { keySerie, color } = getKeySerieAndColor(object, idx, routines, lang);
          reducer.series[keySerie] = reducer.series[keySerie] || { name: keySerie, data: [], color };

          // Calcul de la médiane pour chaque point
          const medianValue = median(Chart.castValuesToNumber(object.values, baseKey));
          reducer.series[keySerie].data.push({
            y: medianValue,
            x: Object.keys(accumulator.categories).indexOf(keyLabel),
            details: object.values,
          });

          return reducer;
        },
        accumulator
      );
    }
    return accumulator;
  }, payload);
};

// Calcul de la médiane
const median = (array: Array<number>) => {
  array = array.filter((x) => x !== null).sort((a, b) => a - b);
  if (!array.length) return null;

  const mid = Math.floor(array.length / 2);
  return array.length % 2 !== 0 ? array[mid] : (array[mid - 1] + array[mid]) / 2;
};

export { doWork };
