<div [ngClass]="{'modal-scroll' : !editMode}">
  <div class="ibox-content">
    <div class="row" *ngIf="editMode">
      <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-row gap-1 mb-1">
            <button class="btn btn-outline-secondary" type="button" (click)="addGraph()">{{ "ADD_GRAPH" | translate }}  <i class="fa fa-plus"></i></button>
            <button class="btn btn-primary" type="button" (click)="reduceGraphs()" translate>REDUCE_GRAPH</button>
          </div>
          <div *ngIf="editMode && graphs.length > 0" class="selectAll">
            <h4>{{ (areAllGraphsSelected() ? 'UNSELECT_ALL_GRAPHS' : 'SELECT_ALL_GRAPHS') | translate }}</h4>
            <dna-checkbox class="ml-1" [isChecked]="areAllGraphsSelected()" [disabled]="false" [text]="selectAllGraphs"
             (onBooleanChanged)="selectUnSelectAllGraphs()"></dna-checkbox>
          </div>
      </div>
    </div>
    <ng-container *ngIf="graphs">
      <div ngbAccordion dragula="DRAGULA_EVENTS" [(dragulaModel)]="graphs">
        <div ngbAccordionItem [collapsed]="true" *ngFor="let graph of graphs; let indexGraph = index">
          <div ngbAccordionHeader class="headerStyle">
            <div class="d-flex align-items-center flex-row justify-content-between p-1">
              <div id="fa-arrows-div" class='col-1'>
                <i id="fa-arrows" class="fa fa-arrows-v"></i>
              </div>
              <div class="col-8">
                <button ngbAccordionButton style="width:100%;" class="d-flex flex-row justify-content-center align-items-center align-self-center btn btn-link p-0">
                  <span class="align-self-center">{{indexGraph+1}}: {{ graph.name ? (graph.name[currentLanguage] || graph.name.english) : "/" }} - {{ graph.type ? (graph.type.name | translate) : ""}}</span>
                </button>
              </div>
              <div class='col-3 d-flex flex-row align-items-center gap-1'>
                <ng-container *ngIf="editMode">
                  <button type="button" class="btn btn-light" (click)="onChangeGraphToCapture(graph)" [disabled]="(!inCampaign && workflow?.state && workflow.state === workflowStates.Published)">
                      <dna-checkbox [isChecked]="isGraphToCapture(graph)" [text]="capture" ngbTooltip="{{ (isGraphToCapture(graph) ? 'REMOVE_TO_CAPTURES' : 'ADD_TO_CAPTURES') | translate }}"
                      placement="left"></dna-checkbox>
                  </button>
                  <button type="button" class="btn btn-info" (click)="duplicateGraph(indexGraph, $event)">
                    <i class="fa fa-files-o"></i>
                  </button>
                  <button class="btn btn-danger" type="button" (click)="removeGraph(indexGraph, $event)">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                  <ng-template>
                <div class="form-group">
                  <div class="py-3 row">
                    <label class="col-md-3 form-control-label" translate>CHARTS.CHART_NAME</label>
                    <div class="col-md-6" *ngIf="editMode; else consultation">
                      <span *ngIf="graph.name; else createGraphName" class="cursor-pointer"
                        (click)="openModalEditComponentName(graph.name, indexGraph)"
                        ngbTooltip="{{ 'EDIT_CHART_NAME' | translate }}">
                        {{ graph.name[currentLanguage] || graph.name.english }}
                      </span>
                      <ng-template #createGraphName>
                        <div class="cursor-pointer" (click)="openModalEditComponentName(graph.name, indexGraph)" translate>
                          EDIT_CHART_NAME
                        </div>
                      </ng-template>
                    </div>
                    <ng-template #consultation><span
                        *ngIf="graph.name">{{ graph.name[currentLanguage] || graph.name.english }}</span></ng-template>
                  </div>
                  <div class="py-3 row">
                    <label class="col-md-3 my-auto form-control-label" translate>CHARTS.CHART_TYPE</label>
                    <div class="col-md-6">
                      <select class="form-control" [ngModel]="graph.type" (ngModelChange)="updateGraphType($event, graph)"
                        [compareWith]="compareGraph" [name]="'graph' + indexGraph" [disabled]="!editMode">
                        <option *ngFor="let graphType of typeGraphs" [ngValue]="graphType" translate>{{ graphType.name }}</option>
                      </select>
                    </div>
                  </div>
                  <dna-power-bi-descriptor-table *ngIf="!isMulti" [graph]="graph" [editMode]="editMode"
                    (onChange)="onChange($event, indexGraph, 'descriptors')">
                  </dna-power-bi-descriptor-table>
                  <dna-descriptor-multi-table *ngIf="isMulti" [graph]="graph" [descriptorGroup]="descriptorGroup" [descriptorAdded]="eventsSubject.asObservable()"
                    [descriptorGroupAdded]="groupEventsSubject.asObservable()" (onChange)="onChange($event, indexGraph)">
                  </dna-descriptor-multi-table>
                </div>
                <div *ngIf="editMode" class="d-flex flex-row gap-1">
                  {{descriptorsGroup}}
                  <button *ngIf="!isConfortChart(graph.type)" class="btn btn-primary" type="button"
                    (click)="addDescriptor(graph, indexGraph, workflow, false)" translate>
                    ADD_DESCRIPTOR
                  </button>
                  <button *ngIf="isGenericChart(graph.type) && !isConfortChart(graph.type)" class="btn btn-primary" type="button"
                    (click)="addDescriptor(graph, indexGraph, workflow, true)" translate>
                    ADD_DESCRIPTOR_GROUP
                  </button>
                  <button *ngIf="graph.type && (graph.type.id === 'ColorationTable' || graph.type.id === 'ColorationTableUS')" class="btn btn-primary" type="button" (click)="chooseCaracterisation(graph, indexGraph)" translate>
                    SELECT_CARAC
                  </button>
                </div>
                  </ng-template>
              </div>
            </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer" *ngIf="!editMode">
  <button class="btn btn-default" type="button" (click)="close()" translate>CLOSE</button>
</div>
