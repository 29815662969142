
import {throwError as observableThrowError,
  Observable
,  Subscription } from 'rxjs';

import {mergeMap, finalize, catchError} from 'rxjs/operators';
import {
  AfterViewInit,
  Component, OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';

import {
  ActionBarButton,
  ActionTypes,
  DNATypes,
  TableHeader,
  Training,
  User
} from '../../types';
import {
  ApplicationInsightsService
} from '../../shared/services/applicationInsights.service';
import {
  DNATranslateService
} from './../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../shared/services/errorManager.service';
import {
  FilterService
} from '../../shared/services/filter.service';
import {
  StateService
} from '../../shared/services/state.service';
import {
  TrainingService
} from '../training.service';
import {
  UserService
} from '../../shared/services/user.service';
import {
  UtilService
} from '../../shared/services/util.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dna-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.less']
})
export class TrainingsComponent implements OnInit, OnDestroy, AfterViewInit {

  allStates: string[];
  filteredTrainings: Training[];
  filter: any;
  showSpinner = true;
  subscribeLang: Subscription;
  subscribeUser: Subscription;
  trainings: Training[];
  initTime = performance.now();

  tableHeaders$: Observable<TableHeader[]>;

  constructor(
    private aiService: ApplicationInsightsService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private stateService: StateService,
    private trainingService: TrainingService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private route: ActivatedRoute,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Training);
    this.allStates = this.stateService.getVisibleStates(this.stateService.TRAININGS);
    this.filter = this.filterService.getFilter().trainings;
    this.trainings = this.trainingService.getTrainingInService();
    this.updateTrainings();

    this.subscribeLang = this.translateService.onLangChange().subscribe(
      (translation: any) => this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Training)
    );

    this.subscribeUser = this.userService.onUserChanged().subscribe((event: User) => this.updateTrainings());
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Trainings', '',  performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
    this.subscribeUser.unsubscribe();
  }

  sort(trainings: Training[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case 'NAME':
        return this.utilService.sortListByType(trainings, 'name', reverse);
      case 'STATE':
        return this.utilService.sortListByType(trainings, 'state', reverse);
      case 'PROGRESSION':
        return this.utilService.sortListByType(trainings, 'progress', reverse);
      case 'DATE_CREATION':
        return this.utilService.sortListByType(trainings, 'created_on', reverse);
      case 'DATE_MODIFICATION':
        return this.utilService.sortListByType(trainings, 'updated_on', reverse);
    }
  }

  onParametersChanged(object: any) {
    this.filter.pageIndex = object.pageIndex;
    this.filter.numberOfObjectsPerPage = object.numberOfObjectsPerPage;
  }

  deleteTraining(training: Training): Observable<any> {
    this.showSpinner = true;
    return this.trainingService.deleteTraining(training.id).pipe(
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }), );
  }

  onFilterChanged(filter) {
    this.filteredTrainings = this.utilService.filterListTraining(this.trainings, filter);
  }

  updateTrainings() {
    this.showSpinner = true;
    this.trainingService.getTrainings().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((trainings: Training[]) => {
        this.trainings = trainings;
        this.trainingService.setTrainingInService(this.trainings);
        this.filteredTrainings = _.cloneDeep(this.trainings);
      },
      error => this.errorManagerService.catchError(error)
      );
  }

  updateData(text: any, type: string, filter: any) {
    filter[type] = text;
    this.onFilterChanged(filter);
  }


  onClickActionButton(actionButton: ActionBarButton, idTraining: string) {
    const training = this.trainings.find(training => training.id === idTraining);
    switch (actionButton.id) {
      case ActionTypes.Remove:
        this.utilService.translateMessageModal('CONFIRM_DELETE', training.name, 'THE_TRAINING').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.deleteTraining(training)), )
          .subscribe(() => {
            _.remove(this.trainings, training);
            this.onFilterChanged(this.filter);
            this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
          });
        break;
    }
  }

}
