import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter, AfterViewInit
} from '@angular/core';

import * as _ from 'lodash';

import {
  Block,
  Translatable,
  BlocDuplicateLocationList,
  KeyValue,
  BLOCK_DUPLICATE,
  WorkflowConfiguration
} from '../../../../../../../../types';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-questions-repetition',
  templateUrl: './questions-repetition.component.html',
  styleUrls: ['./questions-repetition.component.less']
})
export class QuestionsRepetitionComponent implements OnInit, AfterViewInit {

  @Input() workflowConfiguration: WorkflowConfiguration;
  @Input() isWorkflowEditable: boolean;
  @Output() onLoopCreated = new EventEmitter();

  firstItemSelected: number;
  secondItemSelected: number;
  workflow: WorkflowConfiguration;
  labelLoop: any;
  minIndex: number;
  maxIndex: number;
  nbIterationLoop: number;
  range: number[];
  initTime = performance.now();
  blocDuplicateLocationList: typeof BlocDuplicateLocationList = BlocDuplicateLocationList;
  duplicatedBlocLocation: KeyValue = this.blocDuplicateLocationList[1];

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService
  ) { }

  ngOnInit() {
    this.initLoop();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Workflows Questions Repetition', '', performance.now() - this.initTime, templateUrl);
  }

  calculateRange(nbIterationLoop: number) {
    this.range = _.range(0, nbIterationLoop);
  }

  copy(element: Block) {
    return _.cloneDeep(element);
  }

  createLoop(minIndex: number, maxIndex: number, nbIterationLoop: number, labelLoop: any) {
    //Donner un nouvel id au worflow
    this.initLoop();
    this.editBlockLoop(this.workflowConfiguration, minIndex, maxIndex, nbIterationLoop, labelLoop);
    this.onLoopCreated.emit();
  }

  editBlockLoop(workflowConfiguration: WorkflowConfiguration, minIndex: number, maxIndex: number, nbIterationLoop: number, labelLoop: any) {
    let allBlocks: Block[] = [];
    let newBlocks: Block[] = [];
    let blocksToSplice: Block[] = [];
    let blockToChange = workflowConfiguration.blocks.slice(minIndex, maxIndex + 1);
    for (let i = 0; i < nbIterationLoop; i++) {
      let blocks: Block[] = blockToChange.map(this.copy);
      blocks = blocks.map(block => {
        if (!block.sequenceInWorkflow) {
          block.sequenceInWorkflow = {
            name: '',
            idInQuestionnaire: block.idInQuestionnaire
          };
        }
        block.sequenceInWorkflow.name += labelLoop[i] + ' ';

        block.name = this.updateTranslatableField(block.name, labelLoop[i]);
        block.components.map(component => {
          component.args.label = this.updateTranslatableField(component.args.label, labelLoop[i]);
        })
        return block;
      })
      allBlocks = allBlocks.concat(blocks);
      if(i === 0) blocksToSplice = blocks;
      if(i>0) newBlocks = newBlocks.concat(blocks);
    }
    if(this.duplicatedBlocLocation.key === BLOCK_DUPLICATE.AFTER_DUPLICATED_BLOC) {
      workflowConfiguration.blocks.splice(minIndex, maxIndex + 1 - minIndex, ...allBlocks);
    } else {
      workflowConfiguration.blocks.splice(minIndex, maxIndex + 1 - minIndex, ...blocksToSplice);
      workflowConfiguration.blocks.push(...newBlocks);
    }
    workflowConfiguration.blocks = this.setIndexes(workflowConfiguration.blocks);
    workflowConfiguration.blocks = this.updateIndexRadioVersusAndLogicJump(workflowConfiguration.blocks);
  }

  initLoop() {
    this.firstItemSelected = undefined;
    this.secondItemSelected = undefined;
    this.nbIterationLoop = 2;
    this.minIndex = undefined;
    this.maxIndex = undefined;
    this.labelLoop = {};
    this.calculateRange(this.nbIterationLoop);
  }

  setIndexes(blocks: Block[]): Block[] {
    let blocksToCheck = _.clone(blocks);
    while (blocksToCheck.length > 0) {
      let block = blocksToCheck[0];
      let blocksDuplicated = blocks.filter(b => b.idInQuestionnaire.split('@')[0] === block.idInQuestionnaire.split('@')[0]);
      if (blocksDuplicated.length > 1) {
        blocksDuplicated.map((b, i) => {
          b.sequenceInWorkflow.index = i;
          b.idInQuestionnaire = b.sequenceInWorkflow.idInQuestionnaire + '@' + i;
        })
      }
      blocksToCheck = blocksToCheck.filter(b => b.idInQuestionnaire !== block.idInQuestionnaire);
    }
    return blocks;
  }

  setLoopItem(index: number) {
    if (this.firstItemSelected === undefined) {
      this.firstItemSelected = index;
    } else if (this.secondItemSelected === undefined) {
      this.secondItemSelected = index;
      this.minIndex = Math.min(this.firstItemSelected, this.secondItemSelected);
      this.maxIndex = Math.max(this.firstItemSelected, this.secondItemSelected);
    }
  }

  updateData(blocks: Block[], block: Block, data: {
    idInQuestionnaire: string
  }, property: string) {
    let blockTarget = blocks.find(b => b.idInQuestionnaire === data[property]);
    if (!blockTarget) {
      // Le lien a été perdu suite au changement d'idInQuestionnaire
      let blocksDuplicated = blocks.filter(b => b.sequenceInWorkflow && b.sequenceInWorkflow.idInQuestionnaire === data[property]);
      let index = Math.min(block.sequenceInWorkflow ? block.sequenceInWorkflow.index : 0, blocksDuplicated.length - 1);
      data[property] = blocksDuplicated[index].idInQuestionnaire;
    }
  }

  updateIndexRadioVersusAndLogicJump(blocks: Block[]): Block[] {
    for (let block of blocks) {
      //Logic Jump
      if (block.parameters.logicJumps && block.parameters.logicJumps.length > 0) {
        for (let jump of block.parameters.logicJumps) {
          this.updateData(blocks, block, jump, 'idInQuestionnaire');
          this.updateData(blocks, block, jump, 'jumpTo');
        }
      }
      if (block.parameters.logicJumpNext && block.parameters.logicJumpNext.idInQuestionnaire) {
        this.updateData(blocks, block, block.parameters.logicJumpNext, 'idInQuestionnaire');
      }

      for (let component of block.components) {
        //RadioVersus
        if (component.args && component.args.linkRadioVersus && component.args.linkRadioVersus.idInQuestionnaire) {
          this.updateData(blocks, block, component.args.linkRadioVersus, 'idInQuestionnaire');
        }
      }
    }
    return blocks;
  }

  updateTranslatableField(field: Translatable, newLabel: string = "") {
    for (let language in field) {
      if (field[language]) field[language] += ' ' + newLabel;
    }
    return field;
  }

  compare(a: KeyValue, b: KeyValue): boolean {
    if (!b) return false;
    return a.key === b.key;
  }

}
