import {
  AfterViewInit,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap/timepicker/timepicker.module';

import { Block, BlockParameter, NotificationsMode, NotificationsModes, WorkflowConfiguration } from '@app/types';
import {
  DNATranslateService
} from '@app/shared/services/translate.service';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-questions-delay',
  templateUrl: './questions-delay.component.html',
  styleUrls: ['./questions-delay.component.less']
})
export class QuestionsDelayComponent implements OnInit, AfterViewInit {

  @Input() workflowConfiguration: WorkflowConfiguration;
  @Input() isWorkflowEditable: boolean;
  currentLanguage: string;
  dateEndBlock: NgbDateStruct[] = [];
  dateStartBlock: NgbDateStruct[] = [];
  dateFormat: string;
  display: string;
  isErrorOnTime = false;
  notificationsModes: typeof NotificationsModes = NotificationsModes;
  startDate: NgbDateStruct;
  startTime: NgbTimeStruct;
  activeBlocks: Block[];
  initTime = performance.now();

  constructor(
    private translationService: DNATranslateService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService
  ) { }

  ngOnInit() {
    this.dateFormat = this.translationService.getDateFormat();
    this.currentLanguage = this.translationService.getLanguage();
    this.initDatesBlocks();
    this.onChangeDisplay(this.workflowConfiguration.notificationMode);
    this.activeBlocks = this.workflowConfiguration.blocks.filter(block => _.has(block, 'parameters.inactive') ? !_.get(block, 'parameters.inactive') : true);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Workflows Questions Delay', '', performance.now() - this.initTime, templateUrl);
  }

  createDateStart(startDate: NgbDateStruct, startTime: NgbTimeStruct): number {
    let timestamp = - 1;
    if (startDate && startTime) {
      timestamp = new Date(startDate.year, startDate.month - 1, startDate.day).getTime();
      timestamp += startTime.second * 1000 + startTime.minute * 1000 * 60 + startTime.hour * 1000 * 60 * 60;
    }
    return timestamp;
  }

  initDatesBlocks() {
    for (const index in this.workflowConfiguration.blocks) {
      const block = this.workflowConfiguration.blocks[index];
      if (!block.parameters) {
        block.parameters = new BlockParameter();
      }
      const dateStart = new Date(block.parameters.timeStart);
      this.dateStartBlock[index] = {
        year: dateStart.getFullYear(),
        month: dateStart.getMonth() + 1,
        day: dateStart.getDate()
      };
      const dateEnd = new Date(block.parameters.timeEnd);
      this.dateEndBlock[index] = {
        year: dateEnd.getFullYear(),
        month: dateEnd.getMonth() + 1,
        day: dateEnd.getDate()
      };
    }
  }

  onChangeDisplay(data: NotificationsMode = NotificationsModes.OnUserOpen) {
    this.display = data;
    this.workflowConfiguration.notificationMode = data;
    if (data === NotificationsModes.StartDate) {
      if (!this.workflowConfiguration.startDate) {
        this.workflowConfiguration.startDate = new Date().getTime();
      }
      const dateS = new Date(this.workflowConfiguration.startDate);
      this.startDate = {
        year: dateS.getFullYear(),
        month: dateS.getMonth() + 1,
        day: dateS.getDate()
      };
      this.startTime = {
        hour: dateS.getHours(),
        minute: dateS.getMinutes(),
        second: 0
      };
    }
  }

  onDateChanged(date: NgbDateStruct) {
    this.startDate = date;
    this.workflowConfiguration.startDate = this.createDateStart(this.startDate, this.startTime);
  }

  onTimeChanged(time: NgbTimeStruct) {
    this.startTime = time;
    this.workflowConfiguration.startDate = this.createDateStart(this.startDate, this.startTime);
    this.isErrorOnTime = !time;
  }

  updateDelayStartNextBlock(indexBlock: string, blocks: Block[]) {
    const indexBlockNumber = parseInt(indexBlock, 10);
    if (indexBlockNumber + 1 < blocks.length) {
      if (!blocks[indexBlockNumber + 1].parameters) {
        blocks[indexBlockNumber + 1].parameters = new BlockParameter();
      }
      blocks[indexBlockNumber + 1].parameters.delayStart = blocks[indexBlockNumber].parameters.delayEnd;
    }
  }

}
