import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../shared/shared.module';
import { CampaignRoutingModule } from './campaign-routing.module';
import { AnalyseComponent } from './detail/edit-campaign/analyse/analyse.component';
import { MenuComponent } from './detail/edit-campaign/analyse/menu/menu.component';
import { GraphicsModule } from './detail/edit-campaign/analyse/reports/chart-module';
import { ReportGraphDashboardComponent } from './detail/edit-campaign/analyse/reports/dashboard/dashboard.component';
import { ReportLayoutComponent } from './detail/edit-campaign/analyse/reports/reportLayout.component';
import { ReportsComponent } from './detail/edit-campaign/analyse/reports/reports.component';
import { UploadCaptureModalComponent } from './detail/edit-campaign/analyse/reports/upload-capture/upload-capture-modal.component';
import { CampaignDisplayModeComponent } from './detail/edit-campaign/display-mode/display-mode.component';
import { CampaignFormulaComponent } from './detail/edit-campaign/formula/campaign-formula.component';
import { FormulasInsertionModalComponent } from './detail/edit-campaign/formula/formulas-insertion/formulas-insertion-modal.component';
import { RoutinesComponent } from './detail/edit-campaign/formula/routines/routines.component';
import { AddArcsModalComponent } from './detail/edit-campaign/general-information/add-arcs-modal/add-arcs-modal.component';
import { AssociateHorsArcsModalComponent } from './detail/edit-campaign/general-information/associate-hors-arcs-modal/associate-hors-arcs-modal.component';
import { GeneralInformationComponent } from './detail/edit-campaign/general-information/general-information.component';
import { OnePagerHeadComponent } from './detail/edit-campaign/one-pager/one-pager-head/one-pager-head.component';
import { OnePagerPreviewComponent } from './detail/edit-campaign/one-pager/one-pager-preview/one-pager-preview.component';
import { OnePagerTargetProtocolComponent } from './detail/edit-campaign/one-pager/one-pager-target-protocol/one-pager-target-protocol.component';
import { OnePagerComponent } from './detail/edit-campaign/one-pager/one-pager.component';
import { OnePagerResolver } from './detail/edit-campaign/one-pager/one-pager.resolver';
import { ModalAttributionComponent } from './detail/edit-campaign/parameters/attribution/modal-attribution.component';
import { CampaignParameterComponent } from './detail/edit-campaign/parameters/campaign-parameter.component';
import { ConfigureCustomizeModalComponent } from './detail/edit-campaign/parameters/configure-customize/configure-customize-modal.component';
import { AddPhotoModalComponent } from './detail/edit-campaign/photos/gestion-photos/photo-modal/add-photo-modal/addPhotoModal.Component';
import { ManagePhotoModalComponent } from './detail/edit-campaign/photos/gestion-photos/photo-modal/manage-photo-modal/managePhotoModal.component';
import { PhotosComponent } from './detail/edit-campaign/photos/gestion-photos/photos.component';
import { TimepointPhotosComponent } from './detail/edit-campaign/photos/gestion-photos/timepoints/timepointPhotos.component';
import { VolunteerPhotosComponent } from './detail/edit-campaign/photos/gestion-photos/volunteers/volunteerPhotos.component';
import { ProtocolHairComponent } from './detail/edit-campaign/protocol/hair/protocol-hair.component';
import { ProtocolComponent } from './detail/edit-campaign/protocol/protocol.component';
import { ProtocolSkinComponent } from './detail/edit-campaign/protocol/skin/protocol-skin.component';
import { CampaignPublicationComponent } from './detail/edit-campaign/publication/campaign-publication.component';
import { ConfirmPublishModalComponent } from './detail/edit-campaign/publication/confirm-publish-modal/confirm-publish-modal.component';
import { ConfortModalComponent } from './detail/edit-campaign/publication/confort/confort-modal.component';
import { ErrorsImportExcelModalComponent } from './detail/edit-campaign/publication/import-excel/errors-import-excel-modal.component';
import { ImportExcelModalComponent } from './detail/edit-campaign/publication/import-excel/import-excel-modal.component';
import { ModalRandomisationComponent } from './detail/edit-campaign/publication/randomisation/modal-randomisation.component';
import { ModalEditAnswerComponent } from './detail/edit-campaign/raw-data/detail/edit-answer/modal-edit-answer.component';
import { EvaluationDetailComponent } from './detail/edit-campaign/raw-data/detail/evaluation-detail.component';
import { DetailEvaluationRowComponent } from './detail/edit-campaign/raw-data/detail/row/detail-evaluation-row.component';
import { EvaluationsComponent } from './detail/edit-campaign/raw-data/list/evaluations.component';
import { EvaluationRowComponent } from './detail/edit-campaign/raw-data/list/row/evaluation-row.component';
import { TargetHairComponent } from './detail/edit-campaign/targets/hair/target-hair.component';
import { TargetSkinComponent } from './detail/edit-campaign/targets/skin/target-skin.component';
import { TargetComponent } from './detail/edit-campaign/targets/target.component';
import { DropUserRowComponent } from './detail/edit-campaign/users/drop-user-row/drop-user-row.component';
import { UsersComponent } from './detail/edit-campaign/users/users.component';
import { RemoveVisitsModalComponent } from './detail/edit-campaign/visits/remove-visits-modal/remove-visits-modal.component';
import { VisitsBlocksModalComponent } from './detail/edit-campaign/visits/visits-page/visits-blocks-modal/visits-blocks-modal.component';
import { VisitsComponent } from './detail/edit-campaign/visits/visits.component';
import { CampaignWorkflowsComponent } from './detail/edit-campaign/workflows/campaign-workflows.component';
import { AddCharacterizationComponent } from './detail/edit-campaign/workflows/power-bi/add-caracterization/add-characterization.component';
import { PowerBIPageComponent } from './detail/edit-campaign/workflows/power-bi/page/power-bi-page.component';
import { VisitsUpdatedModalComponent } from './detail/edit-campaign/workflows/visits-updated-modal/visits-updated-modal.component';
import { QuestionsActivateComponent } from './detail/edit-campaign/workflows/workflow-edit/detail/questions/activate/questions-activate.component';
import { QuestionsDelayComponent } from './detail/edit-campaign/workflows/workflow-edit/detail/questions/delay/questions-delay.component';
import { QuestionsRepetitionComponent } from './detail/edit-campaign/workflows/workflow-edit/detail/questions/repetition/questions-repetition.component';
import { WorkflowEditDetailComponent } from './detail/edit-campaign/workflows/workflow-edit/detail/workflow-edit-detail.component';
import { WorkflowEditModalComponent } from './detail/edit-campaign/workflows/workflow-edit/workflow-edit-modal.component';
import { CampaignRootComponent } from './detail/root/campaign-root.component';
import { CampaignsComponent } from './list/campaigns.component';
import { CreateCampaignModalComponent } from './list/create-campaign/create-campaign-modal.component';
import { CampaignsRowComponent } from './list/row/campaigns-row.component';
import { MultiTranslationsPipe } from './multiTranslations.pipe';
import { NoWorkspacesComponent } from './no-workspaces/no-workspaces.component';
import { MatRadioModule } from '@angular/material/radio';
import { SafePipe } from './safe.pipe';


@NgModule({
  imports: [
    CampaignRoutingModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    GraphicsModule,
    QuillModule.forRoot(),
    MatRadioModule
    // InfiniteScrollModule
    ],
  exports: [
    MenuComponent
  ],
  declarations: [
    AddCharacterizationComponent,
    AnalyseComponent,
    CampaignFormulaComponent,
    CampaignDisplayModeComponent,
    PhotosComponent,
    CampaignParameterComponent,
    CampaignPublicationComponent,
    CampaignRootComponent,
    CampaignsComponent,
    CampaignsRowComponent,
    CampaignWorkflowsComponent,
    ConfigureCustomizeModalComponent,
    ConfortModalComponent,
    CreateCampaignModalComponent,
    DetailEvaluationRowComponent,
    DropUserRowComponent,
    EvaluationDetailComponent,
    EvaluationRowComponent,
    EvaluationsComponent,
    FormulasInsertionModalComponent,
    GeneralInformationComponent,
    ImportExcelModalComponent,
    ErrorsImportExcelModalComponent,
    MenuComponent,
    ModalAttributionComponent,
    ModalEditAnswerComponent,
    ModalRandomisationComponent,
    OnePagerComponent,
    OnePagerHeadComponent,
    OnePagerPreviewComponent,
    OnePagerTargetProtocolComponent,
    PowerBIPageComponent,
    ProtocolComponent,
    ProtocolHairComponent,
    ProtocolSkinComponent,
    QuestionsActivateComponent,
    QuestionsDelayComponent,
    QuestionsRepetitionComponent,
    ReportLayoutComponent,
    ReportsComponent,
    RoutinesComponent,
    TargetComponent,
    TargetHairComponent,
    TargetSkinComponent,
    UploadCaptureModalComponent,
    UsersComponent,
    VisitsComponent,
    WorkflowEditDetailComponent,
    WorkflowEditModalComponent,
    ReportGraphDashboardComponent,
    AddArcsModalComponent,
    AssociateHorsArcsModalComponent,
    VisitsBlocksModalComponent,
    ConfirmPublishModalComponent,
    VisitsUpdatedModalComponent,
    RemoveVisitsModalComponent,
    NoWorkspacesComponent,
    VolunteerPhotosComponent,
    TimepointPhotosComponent,
    SafePipe,
    ManagePhotoModalComponent,
    AddPhotoModalComponent,
    MultiTranslationsPipe
  ],
  providers: [OnePagerResolver]
})
export class CampaignsModule { }
