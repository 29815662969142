import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';

@Component({
  selector: 'rich-text-wrapper',
  template: '<div #editorContainer></div>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextWrapperComponent),
      multi: true
    }
  ]
})

export class RichTextWrapperComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('editorContainer', { static: true }) editorContainer: ElementRef | null = null;
  @Input() placeholder: string;
  @Input() modules: any;

  private quillEditor: Quill;
  private onChangeCallback: (value: string) => void;
  private onTouchedCallback: () => void;

  ngOnInit(): void {
    this.quillEditor = new Quill(this.editorContainer.nativeElement, {
      theme: 'snow',
      modules: this.modules ?? {
        toolbar: [
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['link']
        ]
      },
      placeholder: this.placeholder,
    });

    this.quillEditor.on('text-change', () => {
      this.onChangeCallback(this.quillEditor.root.innerHTML);
      this.onTouchedCallback();
    });

    this.detectDivChanges();
  }

  ngOnDestroy(): void {
    this.quillEditor.off('text-change', null);
  }

  writeValue(value: string): void {
    this.quillEditor.root.innerHTML = value || '';
  }

  registerOnChange(callback: (value: string) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.quillEditor.enable(!isDisabled);
  }

  detectDivChanges(): void {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver((mutation) => {
      console.log('it works. enjoy :)');
    });
    observer.observe(this.editorContainer.nativeElement, config);
  }
}
